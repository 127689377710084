import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { alpha } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery'

function AppAppBar({ mode }) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState('');

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
      setActiveSection(sectionId);
    }
  };

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            backgroundColor: 'white',
            backdropFilter: 'blur(24px)',
            height: mobile ? 60 : 70,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 1px 2px ${alpha(theme.palette.primary.main, 0.05)}, 0 2px 12px ${alpha(theme.palette.primary.main, 0.5)}`
                : `0 1px 2px ${alpha(theme.palette.primary.main, 0.5)}, 0 2px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 0,
            }}
          >
            <Typography component="p" variant='h4' sx={{ fontSize: mobile ? '1.25rem' : '1.75rem', color: theme.palette.primary.dark, fontWeight: 700, letterSpacing: '0.25rem' }}>HERO KEY</Typography>
            <Box sx={{ 
                display: { xs: 'none', md: 'flex' }, 
                '& .active': {
                  color: theme.palette.primary.main
                },
                '& .MuiButtonBase-root': {
                  fontSize: '1.25rem', 
                  marginRight: 1, 
                  "&:hover" : {backgroundColor: 'transparent', color: theme.palette.primary.main}
                  } 
                }}>
              <Button
                variant="text"
                color="info"
                size="small"
                className={ activeSection == 'home' ? 'active' : '' }
                onClick={() => scrollToSection('home')}
              >
                Home
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                className={ activeSection == 'features' ? 'active' : '' }
                onClick={() => scrollToSection('features')}
              >
                Features
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                className={ activeSection == 'buy-now' ? 'active' : '' }
                onClick={() => scrollToSection('buy-now')}
              >
                Buy Now
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                className={ activeSection == 'contact-us' ? 'active' : '' }
                onClick={() => scrollToSection('contact-us')}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" sx={{ color: theme.palette.primary.main }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: 'background.default',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton sx={{ color: theme.palette.primary.main }} onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                <MenuItem sx={ activeSection == 'home' ? {color: theme.palette.primary.main }: {} } onClick={() => scrollToSection('home')}>
                  Home
                </MenuItem>
                <MenuItem sx={ activeSection == 'features' ? {color: theme.palette.primary.main }: {} } onClick={() => scrollToSection('features')}>
                  Features
                </MenuItem>
                <MenuItem sx={ activeSection == 'buy-now' ? {color: theme.palette.primary.main }: {} } onClick={() => scrollToSection('buy-now')}>
                  Buy Now
                </MenuItem>
                <MenuItem sx={ activeSection == 'contact-us' ?  {color: theme.palette.primary.main }: {}  } onClick={() => scrollToSection('contact-us')}>
                  Contact Us
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
};

export default AppAppBar;
