import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@emotion/react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
export default function Hero() {
  const theme = useTheme()

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <div id="home">
      <Box sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        marginTop: mobile ? '100px' : '110px',
      })}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: mobile ? 'column' : 'row',
            alignItems: 'center',
            pt: { xs: 2, sm: 3 },
            pb: { xs: 2, sm: 3 },
          }}
        >
          <Typography component='p' variant='h5' color='white' sx={{ textAlign: 'center', width: '100%' }}>
            Hero-Key: the essential tool that supports a wide range of cable modes and boosts your productivity.
          </Typography>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          backgroundImage: "URL('../images/main_page.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minHeight: '570px',
          
          width: '100%'
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: mobile ? 'column' : 'row',
            alignItems: 'center',
            pt: { xs: 5, sm: 8 },
            pb: { xs: 2, sm: 5 },
          }}
        >
          <Box sx={{ 
            width: '100%',
            alignItems: 'center'
          }}>
            <Typography component='h1' variant='h1' sx={{ marginBottom: 4, fontWeight: '700', textAlign: mobile ? 'center' : 'left', letterSpacing: '0.25rem' }} >HERO KEY</Typography>
            <Typography component='h5' variant='h5' sx={{ marginBottom: 2, fontSize: '1.875rem', fontWeight: '400', textAlign: mobile ? 'center' : 'left' }}>Universal "10 Mode" cable.</Typography>
            <Typography component='p' variant='h5' sx={{ marginBottom: 2, fontWeight: '400', textAlign: mobile ? 'center' : 'left' }}>ilo pi pona ijo.</Typography>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: mobile ? 'center' : 'flex-start' }}>
              <Button variant="contained" size='medium' color='primary'><PlayCircleIcon />&nbsp;&nbsp;Watch Video</Button>
              <Button variant="outlined" size='medium' color='primary'><CloudDownloadIcon />&nbsp;&nbsp;Download</Button>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <img src='../images/hero_key.png' style={{ width: '100%' }} />
          </Box>
        </Container>
      </Box>
      <Box sx={(theme) => ({
          width: '100%',
          backgroundColor: theme.palette.background.paper
      })}>
        <Container sx={{ p: { xs: 5, sm: 15 }, textAlign: 'center'  }}>
          <Typography component="h2" variant='h2' sx={{ marginBottom: 5, fontWeight: '400' }}>
            Let's talk product
          </Typography>
          <Typography component="p" variant='h5' sx={{ marginBottom: 4, fontWeight: '400'}}>
            Get ready to fall in love with the Hero-Key, the all-in-one device that combines nine different cable modes into one compact and convenient tool. Its intuitive interface and one-click operations make it easy to get connected and stay productive, while its sleek and stylish design makes it a perfect addition to any workspace. Plus, it's cute!
          </Typography>
          <Button variant="contained" size='large' color='primary'>See the Magic</Button>
        </Container>
      </Box>
    </div>
  );
}
