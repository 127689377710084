import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { Grid, Button, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
export default function ContactUs(){
    return (
        <Box id='contact-us' sx={(theme) => ({
            width: '100%',
            backgroundColor: theme.palette.background.paper
        })}>
            <Container sx={{ p: { xs: 5, sm: 15 }, textAlign: 'center'  }}>
                <Typography component="h2" variant='h2' sx={{ marginBottom: 5, fontWeight: '400' }}>
                    Keep in touch?
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            label="Name"
                            sx={{ width: '100%' }}
                            placeholder='Name'
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            label="Email"
                            sx={{ width: '100%' }}
                            placeholder='Email'
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            label="Message"
                            sx={{ width: '100%' }}
                            multiline={Boolean('true')}
                            rows='4'
                            placeholder='Tell us your thoughts and feeling...'
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Button variant='contained' size='large' color='primary'>
                            <SendIcon />&nbsp;&nbsp;Send Message
                        </Button>
                    </Grid>
                </Grid>
            
            </Container>
        </Box>
    )
};