import * as React from 'react';
import Container from '@mui/material/Container';
import { Typography, Box } from '@mui/material'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { useTheme, withTheme } from '@emotion/react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery'
import Fab from '@mui/material/Fab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import clsx from 'clsx'
import Badge from '@mui/material/Badge'

const items = [
  {
    mode: 'USB MODE',
    description:
      'The USB Flashing mode is a standard mode with all functions deactivated. The provided adapter allows you to connect a Type-C device, or you can use a Type-C to micro-USB cable without the adapter.',
  },
  {
    mode: 'EDL MODE',
    description: 'The EDL (Emergency Download) mode enables the EDL cable function, which triggers Qualcomm devices to switch to Qualcomm HS-USB QD-Loader 9008 mode.'
  },
  {
    mode: 'HARMONY MODE',
    description: 'The HAR (Harmony OS) mode enables the Harmony OS cable function on Hi-silicon devices, allowing the device to switch to UART COM/Serial mode.'
  },
  {
    mode: '56K MODE',
    description: 'The 56K Cable mode is a specialized mode utilized for unlocking LG P970 and P990 device models.'
  },
  {
    mode: '130K MODE',
    description: 'The 130K Cable mode is a specialized mode utilized for service mode operations on LG devices.'
  },
  {
    mode: '910K MODE',
    description: 'The 910K Cable mode is a specialized mode utilized for flash operations on LG P970, P990, and unlock operations on P999 devices.'
  },
  {
    mode: '300K MODE',
    description: 'The 300K Cable mode is used to enable the Samsung Factory Mode on all Samsung phones.'
  },
  {
    mode: '523K MODE',
    description: 'The 523K Cable mode allows users to utilize Samsung USB and UART mode functions.'
  },
  {
    mode: 'MTK MODE',
    description: 'The MTK Cable mode allows users to enter boot mode on MTK and SPD devices.'
  },
];


const featureItems = [
  {
    title: 'USB MODE',
    description: 'The USB Flashing mode is a standard mode with all functions deactivated. The provided adapter allows you to connect a Type-C device, or you can use a Type-C to micro-USB cable without the adapter.',
    color: '#00bcd4'
  },
  {
    title: 'EDL MODE',
    description: 'The EDL (Emergency Download) mode enables the EDL cable function, which triggers Qualcomm devices to switch to Qualcomm HS-USB QD-Loader 9008 mode.',
    color: '#8A68FD'
  },
  {
    title: 'HARMONY MODE',
    description: 'The HAR (Harmony OS) mode enables the Harmony OS cable function on Hi-silicon devices, allowing the device to switch to UART COM/Serial mode.',
    color: '#3B86FF'
  },
  {
    title: '56K MODE',
    description: 'The 56K Cable mode is a specialized mode utilized for unlocking LG P970 and P990 device models.',
    color: '#03B0F0'
  },
  {
    title: '130K MODE',
    description: 'The 130K Cable mode is a specialized mode utilized for service mode operations on LG devices.',
    color: '#07C7D1'
  },
  {
    title: '910K MODE',
    description: 'The 910K Cable mode is a specialized mode utilized for flash operations on LG P970, P990, and unlock operations on P999 devices.',
    color: '#6BD682'
  },
  {
    title: '300K MODE',
    description: 'The 300K Cable mode is used to enable the Samsung Factory Mode on all Samsung phones.',
    color: '#D4AD3A'
  },
  {
    title: '523K MODE',
    description: 'The 523K Cable mode allows users to utilize Samsung USB and UART mode functions.',
    color: '#CC40A1'
  },
  {
    title: 'MTK MODE',
    description: 'The MTK Cable mode allows users to enter boot mode on MTK and SPD devices.',
    color: '#8bc34a'
  }
]
export default function Features({ direction = 'rtl' }) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const tabletPC = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [loaded, setLoaded] = React.useState(false)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    rtl: direction === 'ltr',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    slides: {
      perView: 3,
      spacing: 16
    },
    breakpoints: {
      [`(max-width: ${theme.breakpoints.values.sm}px)`]: {
        slides: { perView: 1, spacing: 16 }
      }
    }
  });

  const [featuresLoaded, setFeaturesLoaded] = React.useState(false)
  // const [currentFeatureSlide, setCurrentFeatureSlide] = React.useState(0)
  // const [currentFeatureIndex, setCurrentFeatureIndex] = React.useState(0)
  // const [featureSliderRef, featureInstanceRef] = useKeenSlider(
  //   {
  //     rtl: direction === 'ltr',
  //     loop: true,
  //     slides: {
  //       perView: 2,
  //       spacing: 4
  //     },
  //     slideChanged(slider) {
  //       setCurrentFeatureIndex(slider.track.details.rel)
  //     },
  //     created() {
  //       setFeaturesLoaded(true)
  //     },
  //   }
  // )

  // let timeout
  // const [mouseOver, setMouseOver] = React.useState(false)

  // const changeFeatureIndex = (index = '') => {
  //   if(index === ''){
  //     setCurrentFeatureIndex((currentFeatureIndex + 1) % featureItems.length)

  //   }else{
  //     setCurrentFeatureIndex(index)
  //   }
  // }

  // const clearNextTimeout = () => {
  //   clearTimeout(timeout)
  // }

  // const nextTimeout = () => {
  //   clearTimeout(timeout)
  //   if (mouseOver || tabletPC){
  //     if(tabletPC){
  //       featureInstanceRef.current?.moveToIdx(currentFeatureIndex)
  //     }
  //     return
  //   } 
  //   timeout = setTimeout(() => {
  //     changeFeatureIndex()
  //   }, 5000)
  // }

  // nextTimeout()


  return (
    <>
    <Box sx={{ backgroundColor: 'white' }}>
      <Container id="features" sx={{ py: { xs: 8, sm: 16 }}}>
        <Box ref={sliderRef} className='keen-slider' sx={{ height: '100%' }}>
          {items.map((item, index) => (
            <Box key={index} className='keen-slider__slide'>
              <Box sx={{ position: 'relative', backgroundColor: '#ed6524', padding: '0.5rem 1.125rem', width: 'fit-content', borderBottomRightRadius: '1.25rem', borderTopRightRadius: '1.25rem', borderTopLeftRadius: '1.25rem', top: '1rem'}}>
                <Typography component='p' variant='h5' color='white'>{item.mode}</Typography>
              </Box>
              <Card sx={{ backgroundColor: 'white', minHeight: '13.5rem', borderTopLeftRadius: 0 }}>
                <CardContent sx={{ textAlign: 'center', marginTop: '10px' }}>
                  <Typography component='p' variant='h5' sx={{ fontWeight:400 }}>{item.description}</Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
        {loaded && instanceRef.current && (
        <Box className='swiper-dots' sx={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '1rem' }}>
          {[...Array(instanceRef.current.track.details.slides.length - (mobile ? 0 : 2)).keys()].map(idx => {
            return (
              <Badge
                key={idx}
                variant='dot'
                component='div'
                sx={{ 
                  width: '14px',
                  height: '14px',
                  borderRadius: '7px',
                  backgroundColor: currentSlide === idx ? theme.palette.primary.main : theme.palette.grey[300],
                  cursor: 'pointer'
                 }}
                className={clsx({
                  active: currentSlide === idx
                })}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
              ></Badge>
            )
          })}
          </Box>)}
      </Container>
    </Box>
    {/* <Box id="features" sx={{ backgroundImage: "URL('../images/technical-bg.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
      <Container sx={{ 
        py: { xs: 8, sm: 16 },
        '& .feature-content': {
          backgroundColor: 'rgba(255, 255, 255, .1)',
          borderRadius: '20px',
          padding: '30px',
          minHeight: '520px',
          display: 'flex',
          // minWidth: '400px',
          '& .content-img': {
            '& img': {
              display: 'block',
              width: tabletPC ? '100%' : '300px',
              height: 'auto',
              margin: '0 auto',
            }
          }
        },
        '& .feature-title': {
          backgroundColor: 'rgba(255, 255, 255, .1) !important',
          width: tabletPC ? '100%' : '250px',
          minWidth: tabletPC ? '100%' : '250px',
          padding: tabletPC ? '20px 10px' : '6px',
          height: tabletPC ? '100%' : '50px',
          borderRadius: tabletPC ? '10px' : '30px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          cursor: 'pointer',
          textAlign: 'left',
          display: 'flex',
          flexDirection: tabletPC ? 'column' : 'row',
          gap: tabletPC ? '10px' : 0,
          '& .title-img': {
            display: 'inline-block',
            verticalAlign: 'middle',
            borderRadius: '50%',
            width: tabletPC ? '46px' : '36px',
            minWidth: tabletPC ? '46px' : '36px',
            height: tabletPC ? '46px' : '36px',
            marginRight: '8px',
          },
          '& .title-content':{
            paddingTop: '2px',
            verticalAlign: 'middle',
            display: 'inline-block'
          }
        },
        '& .feature-link': {
          display: 'flex',
          alignItems: 'center'
        }
        }}>
        <Box sx={{ display: 'flex', flexDirection: tabletPC ? 'column' : 'row', gap: tabletPC ? '20px' : 0 }}>
          { !tabletPC ? (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
            {featureItems.map((featureItem, subIndex) => (
                <Box key={subIndex} 
                  className='feature-title'
                  sx={{ border: `2px solid ${currentFeatureIndex !== subIndex ? 'transparent' : featureItem.color}` }} 
                  onMouseEnter={() => {
                    clearNextTimeout()
                    changeFeatureIndex(subIndex)
                    setMouseOver(true)
                  }}
                  onMouseLeave={() => {
                    console.log('here')
                    setMouseOver(false)
                  }}
                >
                  <Box className='title-img' sx={{ border: `2px solid ${featureItem.color}`, backgroundColor: currentFeatureIndex !== subIndex ? 'transparent' : featureItem.color }}>
                    <img src={`./images/technical/technical-icon${subIndex + 1}.png`} style={{ width: '100%' }} />
                  </Box>
                  <Box className='title-content'>
                    <Typography component='p' variant='subtitle2' color='white' sx={{ fontWeight: 600 }}>{featureItem.title}</Typography>
                  </Box>
                </Box>
              ))
            }
          </Box>) : (
            <Box>
              <Box ref={featureSliderRef} className='keen-slider' >
                {featureItems.map((featureItem, subIndex) => (
                  <Box key={subIndex} className='keen-slider__slide'>
                    <Box
                      className='feature-title' 
                      sx={{ display: 'flex', flexDirection: 'column', border: `2px solid ${currentFeatureIndex !== subIndex ? 'transparent' : featureItem.color}` }} 
                      onClick={() => {
                        setMouseOver(true)
                        setCurrentFeatureIndex(subIndex)
                        clearNextTimeout()
                      }}
                    >
                      <Box className='title-img' sx={{ border: `2px solid ${featureItem.color}`, backgroundColor: currentFeatureIndex !== subIndex ? 'transparent' : featureItem.color }}>
                        <img src={`./images/technical/technical-icon${subIndex + 1}.png`} style={{ width: '100%' }} />
                      </Box>
                      <Box className='title-content'>
                        <Typography component='p' variant='subtitle2' color='white' sx={{ textAlign: 'center'}}>{featureItem.title}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              {featuresLoaded && featureInstanceRef.current && (
                <Box className='swiper-dots' sx={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '1rem' }}>
                  {[...Array(featureInstanceRef.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <Badge
                        key={idx}
                        variant='dot'
                        component='div'
                        sx={{ 
                          width: '14px',
                          height: '14px',
                          borderRadius: '7px',
                          backgroundColor: currentFeatureIndex === idx ? featureItems[currentFeatureIndex].color : theme.palette.grey[300],
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          featureInstanceRef.current?.moveToIdx(idx)
                        }}
                      ></Badge>
                    )
                  })}
                  </Box>)}
                </Box>
          ) }
          { !tabletPC ? (<Box className='feature-link'>
            { featureItems.map((featureItem, subIndex) => (
                <img key={subIndex} src={`./images/technical/technical-line${subIndex + 1}.png`} style={{ width: '185.5px', display: subIndex == currentFeatureIndex ? 'block' : 'none' }} />
              ))}
            
          </Box>) : null}
          <Box className='feature-content'>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box className='content-img'>
                <img src={`./images/technical/technical-img${currentFeatureIndex + 1}.png`} />
              </Box>
              <Box>
                <Typography component='p' variant='subtitle2' color='white' sx={{ lineHeight: '1.625' }}>{featureItems[currentFeatureIndex].description}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box> */}
    <Box sx={{ backgroundColor: '#1a1817' }}>
      <Container sx={{ py: { xs: 8, sm: 16 }}}>
        <Box sx={{ px: { xs: 5, sm: 15 }, textAlign: 'center'  }}>
          <Typography component="h2" variant='h2' sx={{ marginBottom: 5, fontWeight: '400' }} color='white'>
            How does it work ?
          </Typography>
          <Typography component="p" variant='h5' sx={{ marginBottom: 4, fontWeight: '400'}} color='white'>
          The Hero-Key makes it easy to connect your devices with the right cable. Simply select the cable mode you need, and the Hero-Key will automatically emulate the original settings of the desired cable mode, including the correct resistor value and connecting the right pins together. No more fussing with multiple cables and adapters - just one convenient tool to get you connected with the desired device.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex',
          justifyContent: 'space-between',
          alignItems: useMediaQuery(theme => theme.breakpoints.down('sm')) ? 'center' : '',
          flexDirection: useMediaQuery(theme => theme.breakpoints.down('sm')) ? 'column' : 'row',
          gap: '20px',
         '& .img-box': {
          position: 'relative',
          maxWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          '& img': {
            width: '100%'
          }
        } }}>
          <Box className='img-box'>
            <Box sx={{ position: 'relative', backgroundColor: '#5cb377', padding: '0.5rem 1.125rem', width: 'fit-content', borderRadius: '1.25rem', borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}>
              <Typography component='p' variant='h5' color='white' sx={{ fontWeight: 400 }}>POWER ON</Typography>
            </Box>
            <img src='../images/device1.png' />
          </Box>
          <Box className='img-box'>
            <Box sx={{ position: 'relative', backgroundColor: '#fcba03', padding: '0.5rem 1.125rem', width: 'fit-content', borderRadius: '1.25rem', borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}>
              <Typography component='p' variant='h5' color='white' sx={{ fontWeight: 400 }}>SELECT MODE</Typography>
            </Box>
            <img src='../images/device2.png' />
          </Box>
          <Box className='img-box'>
          <Box sx={{ position: 'relative', backgroundColor: '#ed6524', padding: '0.5rem 1.125rem', width: 'fit-content', borderRadius: '1.25rem', borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}>
              <Typography component='p' variant='h5' color='white' sx={{ fontWeight: 400 }}>CONNECT TO PHONE</Typography>
            </Box>
            <img src='../images/device3.png' />
          </Box>
        </Box>
      </Container>
    </Box>
    </>
  );
}
